import React from 'react';

import { container, centered, emailLink, imageBox } from './contact-business-card.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { IBusinessCard } from '../../models/business-card.model';
import { useTranslation } from '../../hooks/use-translation';

import Logo, { ILogoProps } from '../atoms/logo';

interface IContactBusinessCardProps {
    className?: string;
    businessCard: IBusinessCard;
    isCentered?: boolean;
    logoFileType?: ILogoProps['fileType'];
}

const ContactBusinessCard: React.FC<IContactBusinessCardProps> = ({
    className = '',
    businessCard,
    isCentered = false,
    logoFileType,
}) => {
    const { groupMember } = businessCard;

    const {
        name,
        email,
        nip,
        street = '',
        streetNumber = '',
        zipCode = '',
        city = '',
    } = useTranslation(businessCard, translationKeys.businessCard);

    return (
        <address className={`${container} ${className} ${isCentered ? centered : ''}`}>
            <Logo
                className={imageBox}
                objectPosition={isCentered ? 'center' : 'left'}
                groupMember={groupMember}
                fileType={logoFileType}
            />
            <p>{name}</p>
            <p>NIP {nip}</p>
            {(street || zipCode || city) && (
                <>
                    <p>
                        {street} {streetNumber}
                    </p>
                    <p>
                        {zipCode} {city}
                    </p>
                </>
            )}
            <a href={`mailto:${email}`} className={emailLink}>
                {email}
            </a>
        </address>
    );
};

export default ContactBusinessCard;
