import React, { useEffect, useRef, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual } from 'fast-equals';

import { mapBox, defaultRatio, ratioBox } from './map.module.scss';

export interface IMapWrapperProps {
    children: React.ReactNode;
}

export interface IMapProps {
    className?: string;
    ratioClass?: string;
    options: google.maps.MapOptions;
    markers?: google.maps.MarkerOptions[];
}

export const MapWrapper: React.FC<IMapWrapperProps> = ({ children }) => {
    return <Wrapper apiKey={process.env.GOOGLE_API_KEY || ''}>{children}</Wrapper>;
};

export const Map: React.FC<IMapProps> = ({
    className = '',
    ratioClass = defaultRatio,
    options,
    markers = [],
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [mapMarkers, setMapMarkers] = useState<google.maps.Marker[]>([]);

    useDeepCompareEffectForMaps(() => {
        if (!ref.current || map) return;
        setMap(new window.google.maps.Map(ref.current, {}));
    }, [ref, map]);

    useEffect(() => {
        if (!map) return;
        map.setOptions(options);
    }, [map, options]);

    useEffect(() => {
        if (!map || !markers?.length) return;
        setMapMarkers(markers.map((marker) => new google.maps.Marker(marker)));
    }, [map, markers]);

    return (
        <div className={className}>
            <div className={`${ratioClass} ${ratioBox}`}>
                <div ref={ref} className={mapBox} />
                {mapMarkers.map((mapMarker, index) => {
                    return React.cloneElement(<Marker key={index} {...mapMarker} />, { map });
                })}
            </div>
        </div>
    );
};

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (!marker) return;
        marker.setOptions(options);
    }, [marker, options]);

    return null;
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a: any, b: any) => {
    if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
    ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    return deepEqual(a, b);
});

function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export default Map;
