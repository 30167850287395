import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    main,
    important,
    formContainer,
    title,
    subtitle,
    addressGrid,
    phoneLink,
    mapRatio,
} from './contact-page.module.scss';
import { KEINO_OFFICE_GOOGLE_MAPS_POSITION } from '../config/consts';
import { businessCards, keinoBusinessCard } from '../config/business-cards';
import { translationKeys } from '../config/translation-keys';
import { statusMap } from '../config/status-map';
import { ISitePageContext } from '../models/site-page.model';
import { useTranslation } from '../hooks/use-translation';
import { useModalContext } from '../contexts/modal-context';

import { Map, MapWrapper, IMapProps } from '../components/organisms/map';
import ContentLayout from '../layouts/content-layout';
import ContactForm, { IContactFormProps } from '../components/organisms/contact-form';
import ContactBusinessCard from '../components/molecules/contact-business-card';

import PhoneIcon from '../assets/images/svg/phone.svg';
interface IContactPageProps {
    readonly pageContext: ISitePageContext;
}

const ContactPage: React.FC<IContactPageProps> = ({ pageContext }) => {
    const { t } = useI18next();
    const { label } = useTranslation(pageContext, translationKeys.pageContext);
    const { addModal } = useModalContext();

    const handleLoading: IContactFormProps['onLoading'] = (helpers) => {
        helpers.setStatus(statusMap.loading);
        addModal({
            type: 'loading',
        });
    };

    const handleSuccess: IContactFormProps['onSuccess'] = (helpers) => {
        helpers.setStatus(statusMap.idle);
        helpers.resetForm();
        addModal({
            type: 'info',
            message: t('form.contact.success'),
        });
    };

    const handleError: IContactFormProps['onError'] = (helpers) => {
        console.log();
        helpers.setStatus(statusMap.error);
        addModal({
            type: 'error',
            message: t('form.contact.error'),
        });
    };

    const { phone, phoneLabel } = useTranslation(keinoBusinessCard, translationKeys.businessCard);

    return (
        <ContentLayout
            SEOProps={{ title: label }}
            className={layout}
            mainClassName={`${main} ${important}`}
        >
            <div className={formContainer}>
                <div>
                    <h1 className={title}>{t('contact.title')}</h1>
                    <h2 className={subtitle}>{t('contact.subtitle')}</h2>
                </div>
                <ContactForm
                    onLoading={handleLoading}
                    onSuccess={handleSuccess}
                    onError={handleError}
                />
            </div>

            {phone && phoneLabel && (
                <a href={`tel:${phone}`} className={phoneLink}>
                    <PhoneIcon />
                    {phoneLabel}
                </a>
            )}

            <div className={addressGrid}>
                {businessCards.map((card) => {
                    return (
                        <ContactBusinessCard
                            key={`contact-business-card-${card.groupMember}`}
                            businessCard={card}
                            logoFileType="svg"
                        />
                    );
                })}
            </div>
            <MapWrapper>
                <Map ratioClass={mapRatio} options={mapOptions} markers={mapMarkers} />
            </MapWrapper>
        </ContentLayout>
    );
};

const mapOptions: IMapProps['options'] = {
    zoom: 17,
    center: KEINO_OFFICE_GOOGLE_MAPS_POSITION,
};

const mapMarkers: IMapProps['markers'] = [{ position: KEINO_OFFICE_GOOGLE_MAPS_POSITION }];

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default ContactPage;
