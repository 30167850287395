import React from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { form, inputs, submit, privacyInfo } from './contact-form.module.scss';
import { postContact } from '../../api/contact';

import FormikForm from '../hoc/formik-form';
import Input from '../atoms/form/input';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';
import Checkbox from '../atoms/form/checkbox';

export interface IContactFormProps {
    className?: string;
    onSuccess?(helpers: FormikHelpers<IContactFormValues>): any;
    onError?(helpers: FormikHelpers<IContactFormValues>): any;
    onLoading?(helpers: FormikHelpers<IContactFormValues>): any;
}

interface IContactFormValues {
    email: string;
    phone: string;
    content: string;
    consent: boolean;
}

const initialValues: IContactFormValues = {
    email: '',
    phone: '',
    content: '',
    consent: false,
};

const ContactForm: React.FC<IContactFormProps> = ({
    className = '',
    onSuccess = () => {},
    onError = () => {},
    onLoading = () => {},
}) => {
    const { t } = useI18next();

    const handleSubmit = (values: FormikValues, helpers: FormikHelpers<IContactFormValues>) => {
        onLoading(helpers);
        postContact({
            email: values.email,
            phone: values.phone,
            content: values.content,
        })
            .then(() => {
                onSuccess(helpers);
            })
            .catch(() => {
                onError(helpers);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
        >
            {(formik) => {
                return (
                    <FormikForm className={`${form} ${className}`} formik={formik}>
                        <div className={inputs}>
                            <Input
                                name="email"
                                type="email"
                                placeholder={t('form.placeholder.email')}
                            />
                            <Input
                                name="phone"
                                type="tel"
                                placeholder={t('form.placeholder.phone')}
                            />
                            <Input
                                name="content"
                                as="textarea"
                                placeholder={t('form.placeholder.content')}
                            />
                            <Checkbox name={'consent'} text={t('form.consent')} />
                        </div>

                        <Button className={submit} text={t('button.send')} />
                        <Markdown className={privacyInfo}>{t('form.privacyInfo')}</Markdown>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

function getValidationSchema(t: ReturnType<typeof useI18next>['t']) {
    return Yup.object({
        email: Yup.string()
            .email(t('form.error.email.format'))
            .required(t('form.error.email.required')),
        phone: Yup.string().required(t('form.error.phone.required')),
        consent: Yup.boolean().oneOf([true], t('form.error.consent.required')),
    });
}

export default ContactForm;
